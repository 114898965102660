var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('div',{staticClass:"md-layout-item",style:(_vm.inquiryShow ? 'cursor: pointer;' : null),on:{"click":function($event){return _vm.openInquiryView(_vm.inquiry.id)}}},[_c('div',{class:`md-layout ${
          _vm.inquiryShow
            ? 'inquiry-container'
            : 'inquiry-container-not-navigable'
        }`,on:{"click":function($event){return _vm.$emit('inquiry-clicked', _vm.inquiry.id)}}},[_c('div',{staticStyle:{"padding":"3px 10px"}},[_c('div',{staticClass:"embed"},[_c('embed',{staticClass:"centered-and-cropped",attrs:{"src":_vm.inquiry.default_image &&
                _vm.isSupportedFileType(_vm.inquiry.default_image)
                  ? `${_vm.baseUrl}/api/inquiries/file_show/${_vm.inquiry.default_image}`
                  : _vm.sampleImage,"alt":"sample image"}})])]),_c('v-divider',{staticClass:"vertical-hr",attrs:{"vertical":""}}),_c('div',{staticClass:"md-layout-item inquiry-data-container"},[_c('v-row',{staticClass:"mt-1 mb-1",staticStyle:{"margin-left":"0px"}},[_c('div',{staticClass:"style-content"},[_vm._v(_vm._s(_vm.inquiry.style_number))])]),_c('truncate-hovering',{staticClass:"truncate-hovering",attrs:{"text":_vm.inquiry.style_name,"length":10,"className":"inquiry-data"}}),_c('div',{staticClass:"inquiry-data"},[_vm._v(" Qty : "+_vm._s(_vm.inquiry.bulk_order_quantity)+" ")]),_c('div',{staticClass:"inquiry-data"},[_vm._v("Colors : "+_vm._s(_vm.inquiry.colour_number))]),_c('div',{staticClass:"inquiry-data"},[_vm._v(" Product Type : "+_vm._s(_vm.inquiry.product_type.name)+" ")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }