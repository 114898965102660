<template>
  <v-card flat class="style_card_container">
    <v-row>
      <div
        class="createBtn-container col-md-4 col-xs-1"
        v-if="isBrandOrCreator"
      >
        <button-card class="btn" @click="openInquiry">
          <div class="button_card_items">
            <ellipse-icon
              class="button_card_icon"
              :width="`45px`"
              :height="`45px`"
              ><v-icon style="color: #582963" large>add</v-icon></ellipse-icon
            >
            <span>Create New Style</span>
          </div>
        </button-card>
      </div>

      <div
        v-for="inquiry in collectionData.inquiries"
        :key="inquiry.id"
        class="miniInquiry col-md-4 col-xs-1"
      >
        <mini-inquiry :inquiry="inquiry" :inquiryShow="true"></mini-inquiry>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import MiniInquiry from "@/components/Inquiry/MiniInquiry.vue";

export default {
  name: "StylesTab",
  components: {
    MiniInquiry,
  },
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
    isBrandOrCreator: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openInquiry() {
      this.$router.push({
        name: "Inquiry",
        params: { id: "new" },
        query: {
          collectionId: this.collectionData.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.style_card_container {
  display: flex;
  justify-items: center;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 30px;
}
.createBtn-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.button_card_items {
  display: flex;
  align-items: center;
}
.button_card_icon {
  float: left;
  margin: 0 15px;
}
.miniInquiry {
  padding: 5px;
}
.btn {
  height: 90px;
  width: auto;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
}
.btn:after {
  width: 0px !important;
}
</style>
